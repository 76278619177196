import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  humanizeBytes,
  NgxUploaderModule,
  UploadFile,
  UploadInput,
  UploadOutput,
} from "ngx-uploader";
import { CommonApiService } from "src/app/core/services/common-api.service";
import { SessionService } from "src/app/core/services/session.service";
import { MessageConstant } from "src/app/core/constants/messages";

import { Router } from "@angular/router";
import { BypassSecurityService } from "src/app/support-service/bypass-security.service";
import { StorageService } from "src/app/core/store/storage.service";
import { Config } from "src/app/core/config/config";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { AngularImageViewerModule } from "angular-15x-img-viewer";
import { CommonModule } from "@angular/common";
declare var $: any;
@Component({
  selector: "app-file-upload",
  standalone: true,
  imports:[PdfViewerModule, AngularImageViewerModule, CommonModule, NgxUploaderModule],
  templateUrl: "./file-upload.component.html",
  styleUrl: "./file-upload.component.scss",
})
export class FileUploadComponent implements OnChanges {
  @Input() img_preview: any = [""];
  @Input() pageCount: any;
  @Input() showParseImg: boolean = false;
  @Input() is_show_drag: boolean = true;
  @Input() options: any;
  @Input() editFlag!: boolean;
  @Input() user_file_id: any;
  @Input() securedFileUrl: any;
  @Input() isShowDeleteButton: boolean = false;
  @Input() allowedFileType: string | undefined;
  @Input() user_file: any;
  @Input() isFromCategory!: boolean;
  @Input() isFromCME!: boolean;

  @Output() FileId: EventEmitter<any> = new EventEmitter();
  @Output() errorMsg: EventEmitter<any> = new EventEmitter();
  uploadInput: EventEmitter<UploadInput>;
  selectedFiles!: FileList;
  is_file: boolean = false;
  is_url_pdf!: boolean;
  is_url_img!: boolean;

  files: UploadFile[];
  humanizeBytes: Function;
  errors = [];
  sizeArrayErrors: any = [];
  state_license_preview: any = [""];
  zoom = 1;
  upload_certificate_exceed: boolean = false;
  upload_certificate_exceed_message = "";
  config = Config.imageViewerConfig;

  is_doc_preview: boolean = false;
  is_audio_preview: boolean = false;
  is_video_preview: boolean = false;

  constructor(
    private commonApiService: CommonApiService,
    private sessionService: SessionService,
    public router: Router,
    private bypassSecurityService: BypassSecurityService,
    private storage: StorageService
  ) {
    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.["user_file_id"]?.currentValue === null) {
      this.is_show_drag = true;
      this.showParseImg = false;
    }

    if (changes?.["options"]?.currentValue) {
      this.options = changes?.["options"]?.currentValue;
    }

    if (
      changes?.["editFlag"]?.currentValue ||
      changes?.["isShowDeleteButton"]?.currentValue
    ) {
      this.showPreviewOnEdit();
    }

    if (changes?.["isFromCategory"]?.currentValue) {
      this.getFileDetails();
    }
  }

  public openFile(): void {
    $("#certificate_upload").click();
  }

  selectFile(event: any) {
    this.selectedFiles = event.target?.files;
    let file: any = this.selectedFiles[0];
    let flag = false;
    this.sizeArrayErrors = [];
    this.errors = [];
    this.options?.allowedContentTypes.forEach((res: any) => {
      if (file?.type.includes(res)) {
        flag = true;
      }
    });
    //check for filesize
    var FileSize: any = file.size / 1024 / 1024; // in MB
    if (FileSize > 10) {
      this.errorMsg.emit({ type: "sizeArrayErrors", error: file.name });
      flag = false;
      return;
    }
    if (!flag) {
      this.errorMsg.emit({
        type: "errors",
        error: MessageConstant.FILE_FORMATE,
      });
      return;
    }
    this.is_file = true;
    this.saveFileUrl(this.selectedFiles[0]);
  }

  onUploadOutput(output: UploadOutput): void {
    if (output.type == "rejected") {
      this.files = [];
      this.upload_certificate_exceed = true;
      if (this.sizeArrayErrors.length) {
        this.upload_certificate_exceed_message =
          "You can upload only one certificate.";
        this.errorMsg.emit({ type: "sizeArrayErrors", error: [] });
      }
      if (this.options.allowedContentTypes.includes(output?.file?.type)) {
        this.errorMsg.emit({
          type: "errors",
          error: "You can upload only one certificate.",
        });
        this.upload_certificate_exceed = false;
        return;
      } else {
        this.errorMsg.emit({
          type: "errors",
          error: "Supported files are PDF and Image. Unknown type upload.",
        });
        this.upload_certificate_exceed = false;
      }

      this.uploadInput.emit({ type: "removeAll" });
      this.uploadInput.emit({ type: "cancelAll" });

      return;
    }
    if (output.type == "addedToQueue") {
      setTimeout(() => {
        if (this.upload_certificate_exceed) {
          this.upload_certificate_exceed_message =
            "You can upload only one certificate.";
          this.upload_certificate_exceed = false;
          this.uploadInput.emit({ type: "removeAll" });
          this.uploadInput.emit({ type: "cancelAll" });
          return;
        } else {
          this.upload_certificate_exceed_message = "";
        }
        let file: any = output?.file?.nativeFile;
        //check for filesize
        var FileSize: any = file.size / 1024 / 1024; // in MB
        if (FileSize > 10) {
          this.sizeArrayErrors.push(file.name);
          this.upload_certificate_exceed_message = "";
          this.upload_certificate_exceed = false;
          this.uploadInput.emit({ type: "removeAll" });
          this.uploadInput.emit({ type: "cancelAll" });
          return;
        }

        this.sizeArrayErrors = [];

        this.upload_certificate_exceed_message = "";
        this.saveFileUrl(output?.file?.nativeFile);
      }, 2000);
    }
  }

  saveFileUrl(file: any): void {
    this.errors = [];
    let formData: FormData = new FormData();
    formData.append("file", file);
    this.commonApiService.saveFileUrl(formData).subscribe({
      next: (res) => {
        let response: any = res;
        this.user_file_id = response.id;
        this.FileId.emit(response.id);
        this.pageCount = response?.page_count;
        this.showParseImg = true;
        this.is_show_drag = false;
        if (file.type.includes("image")) {
          this.is_url_pdf = false;
        } else if (file.type.includes("pdf")) {
          this.is_url_pdf = true;
          this.setPreviewFromFile(file);
        } else if (
          file.type ==
            "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
          file.type == "application/msword" ||
          file.type ==
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          file.type == "application/vnd.ms-powerpoint" ||
          file.type.includes("text")
        ) {
          this.is_doc_preview = true;
          this.is_url_pdf = false;
          this.is_url_img = false;
        } else if (file.type.includes("audio")) {
          this.is_audio_preview = true;
          this.is_url_pdf = false;
          this.is_url_img = false;
        } else if (
          file.type === "application/mp4" ||
          file.type === "video/mp4" ||
          file.type === "video/mpeg" ||
          file.type == "video/quicktime"
        ) {
          this.is_video_preview = true;
          this.is_url_pdf = false;
          this.is_url_img = false;
        }
        this.img_preview[0] =
          this.bypassSecurityService.customBypassSecurityTrustResourceUrl(
            URL.createObjectURL(file)
          );
        this.securedFileUrl = URL.createObjectURL(file);
        if (!file.type.includes("image")) {
          this.img_preview = URL.createObjectURL(file);
        }

        if (this.is_doc_preview) {
          var docUrl = URL.createObjectURL(file);
          this.img_preview = docUrl;
        }

        if (this.is_audio_preview) {
          this.img_preview[0] = URL.createObjectURL(file);
          let sanitizedUrl =
            this.bypassSecurityService.customBypassSecurityTrustResourceUrl(
              this.img_preview[0]
            );
        }

        if (
          file.type.includes("mp4") ||
          file.type.includes("mpeg") ||
          file.type.includes("video/quicktime")
        ) {
          this.img_preview[0] = URL.createObjectURL(file);
          let sanitizedUrl =
            this.bypassSecurityService.customBypassSecurityTrustResourceUrl(
              this.img_preview[0]
            );
        }
      },
      error: (err) => {
        this.errors = [];
        if (err.message == MessageConstant.HTTP_FAILURE) {
          err.message = MessageConstant.SLOW_INTERNET;
        }
        this.errorMsg.emit({ type: "errors", error: err.message });
        let error: any = err;
        this.sessionService.checkUserSession(error);
      },
    });
  }

  private setPreviewFromFile(file: File): void {
    let reader = new FileReader();

    reader.onloadend = (e: any) => {
      this.img_preview = e.target.result;
    };

    reader.readAsArrayBuffer(file);
  }

  public reloadMe(isRemove: any) {
    this.is_show_drag = true;
    this.showParseImg = false;
    this.securedFileUrl = null;
    this.is_file = false;
    if (isRemove) {
      this.deleteCertificate();
    } else {
      this.FileId.emit(null);
    }
  }

    deleteCertificate(): void {
    this.commonApiService.deleteFile(this.user_file_id).subscribe({
      next: (res) => {
        this.is_url_pdf = false;
        this.img_preview[0] = "";
        this.FileId.emit(null);
      },
      error: (err) => {
        let error: any = err;
        this.sessionService.checkUserSession(error);
      },
    });
  }

  public viewFileInNewTab() {
    window.open(this.securedFileUrl, "_blank");
  }

  zoomIn() {
    if (this.zoom == 5) {
      return;
    }
    this.zoom += 0.5;
  }
  zoomOut() {
    if (this.zoom == 0.5) {
      return;
    }
    this.zoom -= 0.5;
  }

  showPreviewOnEdit() {
    if (this.user_file == undefined || this.user_file?.id == undefined) {
      this.showParseImg = false;
      this.securedFileUrl = null;
      this.is_show_drag = true;
      return;
    } else {
      this.commonApiService
        .getCertificateImageVideo(this.user_file?.preview_url)
        .subscribe((res: any) => {
          this.img_preview[0] =
            this.bypassSecurityService.customBypassSecurityTrustResourceUrl(
              URL.createObjectURL(res)
            );
          this.showParseImg = true;
          this.FileId.emit(this.user_file?.id);
          this.user_file_id = this.user_file?.id;
          this.securedFileUrl = this.user_file?.secured_url;
          this.is_show_drag = false;
        });
    }
  }

  getFileDetails(): void {
    this.user_file_id = this.storage?.getUserFileId;
    this.FileId.emit(this.user_file_id);
    let file = JSON.parse(this.storage?.getUserFileDetails);
    this.img_preview[0] = file?.preview_url;
    this.commonApiService
      .getCertificateImageVideo(file?.preview_url)
      .subscribe((res: any) => {
        this.showParseImg = true;
        this.is_show_drag = false;
        this.img_preview[0] =
          this.bypassSecurityService.customBypassSecurityTrustResourceUrl(
            URL.createObjectURL(res)
          );
      });
  }
}
