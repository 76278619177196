export class Config {
  public static datePickerConfig = {
    showWeekNumbers: false,
    dateInputFormat: "MM/DD/YYYY",
    useUtc: false,
  };

  public static imageViewerConfig = {
    allowFullscreen: true,
    btnShow: {
      zoomIn: true,
      zoomOut: true,
      rotateClockwise: true,
      rotateCounterClockwise: true,
      next: false,
      prev: false,
    },
  };

  public static categoryCMESliderConfig = {
    slidesToShow: 5.3,
    slidesToScroll: 5,
    infinite: false,
    nextArrow:
      "<div class='nav-btn flag-next-slide'> <img src='../../../assets/images/dashboard-imgs/blue-up-arrow.svg'> </div>",
    prevArrow:
      "<div class='nav-btn flag-prev-slide'> <img src='../../../assets/images/dashboard-imgs/blue-up-arrow.svg'> </div>",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
    ],
  };

  public static paginationData = {
    pageNo: 1,
    itemsPerPage: 20,
    totalRecords: 0,
  };
}
