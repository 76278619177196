import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { NgSelectModule } from "@ng-select/ng-select";
import { AngularImageViewerModule } from "angular-15x-img-viewer";
import { NgxUploaderModule } from "ngx-uploader";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxPaginationModule } from "ngx-pagination";
import { TagInputModule } from "ngx-chips";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { ModalModule } from "ngx-bootstrap/modal";
import { CoreModule } from "src/app/core/core.module";
import { FileUploadComponent } from "src/app/shared-resources/file-upload/file-upload.component";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    BsDatepickerModule,
    NgSelectModule,
    AngularImageViewerModule,
    NgxUploaderModule,
    PdfViewerModule,
    NgxSpinnerModule,
    NgxPaginationModule,
    TagInputModule,
    TypeaheadModule,
    FileUploadComponent,
    ModalModule.forRoot(),
  ],
  exports: [
    AngularImageViewerModule,
    NgxUploaderModule,
    PdfViewerModule,
    BsDatepickerModule,
    NgSelectModule,
    NgxSpinnerModule,
    TagInputModule,
    TypeaheadModule,
  ],
})
export class SharedModule {}
