<div *ngIf="is_show_drag" class="dropzone" ngFileDrop [options]="options" [uploadInput]="uploadInput"
  (uploadOutput)="onUploadOutput($event)">
  <div class="dropzone__content mb-10">
    <img src="../../../assets/images/review-files.svg" class="dropzone-img" />
    <div (click)="openFile()">
      <input type="submit" name="commit" value="Upload Evidence" class="btn btn-secondary mt-10 mb-6" />
    </div>
    <h6 class="font-size-12">{{(allowedFileType?allowedFileType:'Only pdf and image file with Max 10 MB is
      supported.')}}</h6>
  </div>
  <div class="fallback">
    <div class="form-group">
      <label class="control-label" for="certification_certificate_file"> </label>
      <input type="file" class="display-none" (change)="selectFile($event)" id="certificate_upload"
        [accept]="options.allowedContentTypes" />
    </div>
  </div>
</div>
<div class="form-group" *ngIf="showParseImg">
  <div style="position:relative">
    <div class="page-count" *ngIf="pageCount !== null">
      {{ pageCount }} page<span *ngIf="pageCount > 1">s</span>
    </div>
    <angular-11-image-viewer id="image_viewer" class="certification__preview" [src]="img_preview"
      *ngIf="!(is_url_pdf ||is_doc_preview || is_audio_preview || is_video_preview)">
    </angular-11-image-viewer>
    <div class="pdf-viewer" [class.cme-preview-height]="isFromCME" *ngIf="is_url_pdf">
      <pdf-viewer id="pdf_viewer" class="mb_custom_pdf" [zoom]="zoom" [src]="img_preview" [show-all]="true"
        [original-size]="true" [page]="1" [fit-to-page]="true" style="display: block"></pdf-viewer>
    </div>
    <!-- <ngx-doc-viewer [url]="img_preview" viewer="google" *ngIf="is_doc_preview" class="w-100-p h-50-vh">
      </ngx-doc-viewer> -->

    <!-- <audio controls class="ml-20-p" id="audio" *ngIf="is_audio_preview">
        <source [src]="sanitize(img_preview)" type="audio/ogg" />
        <source [src]="sanitize(img_preview)" type="audio/mpeg" />
        <source [src]="sanitize(img_preview)" type="audio/AMR" />
        <source [src]="sanitize(img_preview)" type="audio/mp2" />
        <source [src]="sanitize(img_preview)" type="audio/x-ms-wma" />
  
        Your browser does not support the audio element.
      </audio> -->
    <!-- <video width="100%" height="300" controls *ngIf="is_video_preview">
        <source [src]="sanitize(img_preview)" type="video/mp4" />
        <source [src]="sanitize(img_preview)" type="video/ogg" />
        Your browser does not support the video tag.
        <track label="English" kind="captions">
      </video> -->
  </div>

  <div *ngIf="img_preview.length != 0 && editFlag" class="text-center d-flex justify-content-between mt-10">
    <button id="view_btn" class="btn btn-primary btn-sm" data-bs-disable-with="View"
      (click)="viewFileInNewTab()">View</button>
    <button id="replace_btn" class="btn btn-primary btn-sm" data-bs-disable-with="Replace"
      (click)="openFile()">Replace</button>
    <button id="delete_btn" *ngIf="!isShowDeleteButton" class="btn btn-primary btn-sm" data-bs-disable-with="Delete"
      (click)="reloadMe(true)">Delete</button>
    <input type="file" style="display: none" (change)="selectFile($event)" id="certificate_upload"
      accept="image/jpeg,image/gif,image/png,application/pdf" />
  </div>
</div>