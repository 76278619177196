export class MessageConstant {
  public static FORGOT_PASSWORD_SUCCESS =
    'You will receive an email with instructions on how to reset your password in a few minutes.';
  public static INVALID_EMAIL = 'Please enter a valid email address.';
  public static INVALID_USERNAME = 'Please enter the valid name.';
  public static INVALID_title = 'Please enter the valid title.';
  public static EMPTY_EMAIL = 'Please enter email.';
  public static INVALID_USERNAME_EMPTY_FIELD = 'Please enter the name';
  public static INVALID_FILENAME_EMPTY_FIELD = 'Please enter name.';
  public static FILENAME_CHARACTER_LENGTH = 'Name should not contain more than 255 characters.';
  public static INVALID_PASSWORD = 'Invalid Password.';
  public static INVALID_CONFIRM_PASSWORD = 'Invalid Confirm Password.';
  public static INVALID_PWD_LENGTH = 'Password & Confirm Password should be maximum 40 characters.';
  public static PWD_VALIDATE_FAILED = 'Password & Confirm Password should be minimum 8 characters.';
  public static PWD_CONFIRM_SHOULD_BE_SAME = 'Password & Confirm password should be same.';
  public static SIGN_UP_SUCCESS_MESSAGE =
    'A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.';
  public static SIGN_OUT_SUCCESS = 'Logged out successfully.';
  public static FILE_FORMATE_MESSAGE = 'Supported files are Image,PDF. Unknown file type uploaded';
  public static DATE_OF_EXPIRATION = 'Must select Date of expiration.';
  public static FILE_FORMATE = 'Supported files are PDF and Image. Unknown file type uploaded.';
  public static AT_LEAST_ONE_EMAIL = 'Please enter at least one email address.';
  public static INVALID_EMAIL_OR_USER = 'Invalid Username/Email or password.';
  public static SOMETHING_WENT_WRONG = 'Something went wrong, please try again.';
  public static SOMETHING_WENT_WRONG_TASK =
    'Something went wrong, unable to update the Task Status';
  public static EMPTY_EXAM_TYPE = 'Please select exam type.';
  public static ONLY_ONE_CERTIFICATE = 'You can upload only one certificate';
  public static HTTP_FAILURE = 'Http failure response for (unknown url): 0 Unknown Error';
  public static SLOW_INTERNET =
    'We are experiencing slow or intermittent connectivity to our servers. Please try again later. If this issue persists, please contact our support.';
  public static INTERNAL_SERVER = 'Internal Server Error';
  public static EMPTY_EDUCATIONAL_INSTITUTE = 'Please enter name of the educational institution.';
  public static VALID_EDUCATIONAL_INSTITUTE = 'Please enter the valid educational institution.';
  public static TYPE_OF_CREDIT = 'Please select Type of Credits.';
  public static EMPTY_COURSE_CODE = 'Please enter course code.';
  public static VALID_COURSE_CODE = 'Please enter valid course code.';
  public static EMPTY_COURSE_NAME = 'Please enter course name.';
  public static VALID_COURSE_NAME = 'Please enter valid course name.';
  public static CREDIT_LIMIT = 'Credits should be greater than 0.';
  public static EMPTY_FINAL_GRADE = 'Please enter Final Grade.';
  public static VALID_FINAL_GRADE = 'Please enter valid Final Grade.';
  public static EMPTY_DATE_RECEIVED = 'Please enter Date Received.';
  public static AT_LEAST_ONE_SPECIALTY =
    'At least one specialty or state license must be provided for academic credits to be associated with.';
  public static EMPTY_DATE_OF_PRESENTATION = 'Please select the Date of presentation.';
  public static EMPTY_NUMBER_OF_CLOCL_HOURS = "Number of clock hours should be greater than '0'.";
  public static AT_LEAST_ONE_REQUIREMENTS = 'Please select at least one of the requirements.';
  public static AT_LEAST_ONE_PRESENTED = 'Please select at least one of the presented at option.';
  public static EMPTY_PRESENTED = 'Please enter presented at other field.';
  public static VALID_PRESENTED = 'Please enter valid details for presented at other.';
  public static EMPTY_OTHER_TITLE = 'Please enter title.';
  public static VALID_PROJECT_TITLE = 'Please enter valid project title.';
  public static AT_LEAST_ONE_PROJECT_TYPE = 'Please select at least one project type.';
  public static EMPTY_PROJECT_DATE = 'Please select the project start date';
  public static EMPTY_PROJECT_END_DATE = 'Please select the project end date.';
  public static VALID_PROJECT_END_DATE = 'Project end date should be after start date.';
  public static AT_LEAST_ONE_SPECIALTY_OR_STATE_LICENSE =
    'At least one specialty or state license must be provided for project file to be associated with.';
  public static EMPTY_PUBLICATION_TITLE = 'Please enter publication title.';
  public static VALID_PUBLICATION_TITLE = 'Please enter the valid publication title.';
  public static EMPTY_RELATED_SPECIALIZATION =
    'Please select the related specialization at least one of below.';
  public static AT_LEAST_ONE_PUBLICATION =
    'Please select the publication type at least one of below.';
  public static AT_LEAST_ONE_CONTRIBUTION =
    'Please select the my contribution at least one of below.';
  public static SELECT_PROJECT_DATE = 'Please select the project date.';
  public static EMPTY_RESEARCH_PROJECT_TITLE = 'Please enter the research project title.';
  public static VALID_RESEARCH_PROJECT_TITLE = 'Please enter the valid research project title.';
  public static AT_LEAST_ONE_SPECIALTY_FOR_RESEARCH =
    'At least one specialization must be provided for research file to be associated with.';
  public static EMPTY_RESEARCH_TYPE = 'Must select research type.';
  public static EMPTY_OTHER_ACTIVITY = 'Must enter the other activity.';
  public static VALID_OTHER_RESEARCH_TYPE = 'Please enter valid other research type details.';
  public static EMPTY_OTHER_RESEARCH_TYPE = 'Please enter the other research type details.';
  public static EMPTY_SELECT_CONTRIBUTION = 'Must select contribution.';
  public static EMPTY_CLAIMED_HOUR = "number of claimed hours should be greater than '0'.";
  public static EMPTY_TYPE_OF_STUDENT =
    'Please select the type of student supervise/taught at least one of below.';
  public static AT_LEAST_ONE_BELOW = 'Please select at least one of below.';
  public static VALID_VOLUNTEER_SERVICE = 'Please enter valid volunteer service completed hours.';
  public static VALID_VOLUNTEER_SERVICE_HOUR =
    'Please enter volunteer service completed hours greater than 0.';
  public static AT_LEAST_ONE_SERVICE = 'Please select at least one type of service.';
  public static EMPTY_CLAIMED_PRACTICE_HOUR = 'Please enter the number of claimed practice hours.';
  public static AT_LEAST_ONE_TYPE_OF_PRACTICE = 'Please select at-least one type of practice hour.';
  public static EMPTY_START_DATE_OF_PRACTICE = 'Please select the start date for practice hours.';
  public static EMPTY_END_DATE_OF_PRACTICE = 'Please select the end date for practice hours.';
  public static VALIDATE_END_DATE_WITH_START_DATE =
    'End date for practice hours should be after start date for practice hours.';
  public static EMPTY_COMPLETION_DATE = 'Please select the completion date.';
  public static EMPTY_RECEIPT_LABEL = 'Please enter Receipt Label.';
  public static VALID_RECEIPT_LABEL = 'Please enter the valid Receipt Label.';
  public static EMPTY_PAYMENT_DATE = 'Please enter Payment Date.';
  public static EMPTY_PAYMENT_TYPE = 'Please enter Purchase Type.';
  public static EMPTY_AMOUNT_PAID = 'Please enter Amount Paid.';
  public static EMPTY_REIMBURSEMENT = 'Please select Reimbursement.';
  public static EMPTY_VENDOR_NAME = 'Please enter vendor name.';
  public static VALID_VENDOR_NAME = 'Please enter the valid vendor name.';
  public static EMPTY_ACTIVITY_PROVIDER = 'Please enter activity provider.';
  public static EMPTY_ACTIVITY_TYPE = 'Please select activity type.';
  public static EMPTY_APPLICABLE_CREDIT = 'Please select applicable credits';
  public static EMPTY_HOURS_COMPLETED = 'Please enter hours completed';
  public static EMPTY_CREDIT_COMPLETED = 'Please enter credits completed.';
  public static EMPTY_POINTS_COMPLETED = 'Please enter points completed.';
  public static EMPTY_DATE_COMPLETED = 'Please enter date completed.';
  public static AT_LEAST_ONE_SPECIALTY_isQpi = 'At least 1 specialty must be selected.';
  public static AT_LEAST_ONE_STATE_LICENSE_isQpi = 'At least 1 state license must be selected.';
  public static EMPTY_PATIENT_INITIALS = "Patient initials can't be blank.";
  public static VALID_PATIENT_INITIALS = 'Please enter valid patient initials.';
  public static VALID_DOB_FUTURE = "Date of birth can't be in the future.";
  public static VALID_DOB = 'Date of birth should be a valid date.';
  public static VALID_DATE_OF_SERVICE = 'Date of service should be a valid date.';
  public static EMPTY_DATE_OF_SERVICE = "Date of service can't be blank.";
  public static EMPTY_PROCEDURE_TYPE = "Procedure type can't be blank.";
  public static VALID_PROCEDURE_TYPE = 'Please enter valid procedure type.';
  public static EMPTY_PROCEDURE_CATEGORY = "Procedure category can't be blank.";
  public static VALID_PROCEDURE_CATEGORY = 'Please enter valid procedure category.';
  public static EMPTY_DESCRIPTION = "Description can't be blank.";
  public static VALID_DESCRIPTION = 'Please enter valid description.';
  public static VALID_COMPLICATION = 'Please enter valid complication.';
  public static VALID_DATA = 'Please enter valid data in data fields.';
  public static PHI_PRESENT =
    'Please accept that there is no PHI present in the case notes by selecting the checkbox at the bottom of the form.';
  public static EMPTY_DETAILS = 'Please enter details.';
  public static EMPTY_CONTACT_DETAILS = 'Please enter correct details.';
  public static EMPTY_TITLE = "Must enter 'Title'.";
  public static EMPTY_PROVIDER_NAME = "Must enter 'Document Source'.";
  public static VALIDATE_SCORE_ARCHIVE = "'Score Achieved' should be greater than zero.";
  public static VALIDATE_PASSING_SCORE = "'Passing Score' should be greater than zero.";
  public static VALIDATE_ACHIEVABLE_SCORE = "'Total Achievable Score' should be greater than zero.";
  public static EMPTY_ISSUE_DATE = "Must select 'Issue date'.";
  public static EMPTY_DATE = "Must select 'Date'.";
  public static EMPTY_CREDIT_TYPE = "Must select 'Credit Type'.";
  public static EMPTY_FORMAT = "Must select 'Format'.";
  public static EMPTY_CERTIFICATION_TYPE = "Must select 'Document Type'.";
  public static VALIDATE_ISSUE_DATE = 'Issued date should not be greater than current date.';
  public static AT_LEAST_ONE_SPECIALTY_FOR_CERTIFICATE =
    'At least one specialty or state license must be provided for certification to be associated with.';
  public static VALIDATE_NO_OF_SUB_CREDIT =
    'Number of sub-credits for category requirement should be greater than 0.';
  public static EMPTY_WAIVER_DATE = 'Must select waiver date.';
  public static VALIDATE_NO_OF_SUB_CREDIT_LIMIT =
    'Number of sub-credits for state license requirement should be greater than 0.';
  public static VALIDATE_NO_OF_SUB_CREDIT_LIMIT_FOR_SPECIALTY =
    'Number of sub-credits for specialty requirement should be greater than 0.';
  public static VALIDATE_NO_OF_STANDALONE =
    'Number of standalone-credits should be greater than 0.';
  public static EMPTY_STATE = 'Please select your state.';
  public static EMPTY_CREDENTIALS = "Must enter 'Credential'.";
  public static EMPTY_EFFECTIVE_DATE = "Must select 'Effective date'.";
  public static EMPTY_EXPIRATION_DATE = "Must select 'Expiration date'.";
  public static VALIDATE_EFFECTIVE_LICENSE_DATE = 'Effective license date should be a past date.';
  public static EMPTY_STATE_MESSAGE = 'Please select your state.';
  public static EMPTY_REGISTRATION_NUMBER = "Must provide 'Registration number'.";
  public static VALID_REGISTRATION_NUMBER = 'Please enter valid Registration number';
  public static EMPTY_REGISTRATION_DATE = "Must select 'Initial Registration Date'.";
  public static VALIDATE_REGISTRATION_DATE = 'Initial registration date should be a past date.';
  public static INVALID_REGISTRATION_DATE = 'Invalid initial registration date.';
  public static VALID_EXPIRATION_DATE = 'Invalid Expiration date.';
  public static SOMETHING_WRONG = 'Something went wrong, please try again.';
  public static VALID_NUMBER_OF_FILES = 'You can only upload a maximum of 50 files.';
  public static VALID_FILE_FORMATE =
    'Supported files are PDF,Image,Audio,Doc,Video,ppt. Unknown file type uploaded.';
  public static REMOVE_FILE_MSG = 'Please confirm that you would like to remove the file.';
  public static FILE_PROCESSING_MSG =
    "Great! Our virtual assistant is processing your file now. We'll let you know when your files have been sorted, categorized, and credited.";
  public static EMPTY_NPI = 'Please enter NPI.';
  public static VALID_NPI = 'Please enter valid NPI number.';
  public static SELECT_NPI = 'Please select an NPI.';
  public static WRONG_NPI = 'NPI is the wrong length (should be 10 characters).';
  public static EMPTY_FIRST_NAME = 'Please enter first name.';
  public static VALID_FIRST_NAME = 'Please enter valid first name.';
  public static EMPTY_LAST_NAME = 'Please enter last name.';
  public static VALID_LAST_NAME = 'Please enter valid last name.';
  public static VALID_DOB_PROFILE = 'Date of Birth should not be greater than current date.';
  public static EMPTY_DOB = "Must select 'Date of Birth'.";
  public static VALID_PHONE_NUMBER = 'Please enter valid phone number.';
  public static VALID_ZIP = 'Please enter valid zip code.';
  public static EMPTY_POSTCODE = "Profile update failed: Postcode can't be blank.";
  public static EMPTY_ZIP_CODE = "Profile update failed: Zip code can't be blank.";
  public static EMPTY_USERNAME = 'Please enter user name.';
  public static VALID_USERNAME = 'Please enter valid data in username field.';

  public static EMPTY_CURRENT_PASSWORD = 'Please enter current password.';
  public static EMPTY_NEW_PASSWORD = 'Please enter new password.';
  public static EMPTY_CONFIRM_PASSWORD = 'Please enter confirm password.';
  public static VALIDATE_SAME_PASSWORD = "Current password and new password can't be same.";
  public static VALID_FILE_SELECTION = 'Please select valid file.';
  public static NO_IMG_SELECTED = 'Please select the image.';
  public static IMG_UPLOADED_SUCCESSFULLY = ' Profile image uploaded successfully.';
  public static FAILED_TO_DISCONNECT = 'Failed to disconnect, please try again.';
  public static FAILED_TO_UPDATE_PREFILLED_DATA = 'Failed to update prefilled data.';
  public static EMPTY_PERSONAL_PROFILE = 'Please complete the personal profile.';
  public static FAILED_TO_UPDATE_STEP = 'Failed to update the step.';
  public static ADD_SPECIALTY_OR_FILES = 'Add the specialization or files to generate the tasks.';
  public static ADD_FILES = 'Add files to generate the tasks.';
  public static TASK_MARKED_AS_COMPLETE = 'The task you selected has been marked as complete.';
  public static ALL_TASK_MARKED_AS_COMPLETE =
    'Congratulations, all tasks you have completed for this cycle have been marked as complete.';
  public static INVALID_PASSWORD_FIELD = 'Password field cannot be blank.';
  public static INVALID_CONFIRM_PASSWORD_FIELD = 'Confirm password field cannot be blank.';
  public static USERNAME_ALREADY_TAKEN =
    'Error updating password. Username has already been taken.';
  public static PWD_IS_TOO_SHORT = 'Password is too short (minimum is 8 characters).';
  public static FAILED_TO_SET_PASSWORD = 'Failed to setup password.';
  public static SERVICE_UNAVAILABLE = 'This service is temporarily unavailable.';
  public static EMPTY_RENEWAL_OPTION = 'Please select a renewal option.';
  public static EMPTY_EXPIRATION_YEAR = 'Please select current expiration year.';
  public static EMPTY_RECERTIFICATION_YEAR = 'Please select recertification exam year.';
  public static EMPTY_ABOS_WLA_YEAR = 'Please select ABOS WLA start year.';
  public static EMPTY_YEAR_OF_OLA = 'Please select first year participating in OLA.';
  public static EMPTY_INITIAL_CERTIFICATE_DATE = 'Please select initial certification date.';
  public static FAILED_TO_SAVE_SPECIALIZATION = 'Failed to save specializations.';
  public static FAILED_TO_UPDATE_PROFESSIONAL_PROFILE = 'Failed to update Professional Profile.';
  public static EMPTY_LICENSE_TYPE = 'Please select License type.';
  public static EMPTY_LICENSE_NUMBER = 'Must provide License number.';
  public static VALID_LICENSE_NUMBER = 'Please enter valid license number.';

  public static EMPTY_INITIAL_LICENSE_DATE = 'Please select the initial license date.';
  public static VALID_INITIAL_LICENSE_DATE = 'Initial license date should be a past date.';
  public static VALID_INITIAL_LICENSE_DATE_AFTER_DATE_OF_EXP =
    'Initial License date cannot be after the Date of expiration.';
  public static EMPTY_ONE_TIME_REQ = 'Please select one time requirement of pain management.';
  public static NO_AVAILABLE_TRACKER = 'Unable to get available trackers. Please try again.';
  public static DOB_LESS_THAN_EXP = 'Date of Birth should not be greater than expiry date.';
  public static NO_SERVICE_FOR_NPI = 'NPI lookup service is temporarily unavailable.';
  public static FILES_IS_BEING_SORTED =
    'Thank you for uploading the files. The MOCINGBIRD team will shortly get those files sorted for you and let you know when its done.';
  public static EMPTY_NO_OF_QUESTIONS = 'Please select number of question(s) answered.';
  public static EMPTY_CORRECT_TASK =
    'Please select correct task to update number of question(s) answered.';
  public static VALID_DATE = 'Please select valid date.';
  public static SPECIALIZATION_DELETED = 'Specialization deleted successfully.';
  public static VERIFIED_LOGIN_AGAIN = 'Invitation is verified. Please login to continue.';
  public static INVALID_USER_OR_TOKEN = 'Invalid user invitation token.';
  public static SUCCESS_ZIP_DOWNLOADED = 'Download zip file is successful.';
  public static FILE_NO_LONGER_AVAILABLE =
    'The file export is no longer available. Please contact the user who sent you the file to resend the file.';
  public static INVALID_PASS_CODE =
    'Provided pass code is incorrect. Try again with the correct one.';
  public static EMPTY_GROUP_NAME = "Group name can't be blank.";
  public static VALID_USER_TYPE = 'User can type up to 30 char only.';
  public static EMPTY_GROUP_LEADER = "Group leader can't be blank.";
  public static EMPTY_GROUP_MEMBER = "Group member can't be blank.";
  public static ALREADY_USER_OR_LEADER = 'One or more user are already added as leader and member.';
  public static NAME_ALREADY_TAKEN = 'Error creating group. Name has already been taken.';
  public static FILE_FORMAT_XLSX =
    'Support File format .xlsx. Please use the supplied sample template.';
  public static EMPTY_SUPPLIED_SAMPLE = 'Please refer supplied sample template for importing file.';
  public static FILE_UPLOADED_PLEASE_REFRESH =
    'File uploaded successfully. Please refresh the page after a few minutes to view status.';
  public static FAILED_TO_SAVE = 'Failed to save file data.';
  public static USERNAME_ALREADY_EXIST = 'Username has already been taken.';
  public static EMAIL_ALREADY_EXIST = 'Email has already been taken.';
  public static FAILED_TO_INVITE_USER = 'Failed to invite user.';
  public static INVITATION_SENT = 'Invite sent successfully!';
  public static USER_REMOVED_SUCCESSFULLY = 'User removed from organization successfully.';
  public static FILE_FORMATE_PDF_DOC_TEXT =
    'Supported files are PDF,Image,Doc and Text. Unknown type upload.';
  public static EMPTY_USER_ROLE = 'Please select user role.';
  public static EMPTY_INTEREST_IN_PROJECT = 'Please select interested in project.';
  public static EMPTY_MAILING_ADDRESS = 'Please enter mailing street address.';
  public static EMPTY_MAILING_CITY = 'Please enter mailing city.';
  public static EMPTY_MAILING_STATE = 'Please enter mailing state.';
  public static EMPTY_MAILING_ZIP = 'Please enter mailing zip code.';
  public static EMPTY_DOCUMENT_SOURCE = "Must select 'Document Source'.";
  public static SELECT_IMLC_MESSAGE = 'Please select interstate medical licensure compact option.';
  public static SELECT_STATE = 'Please select state for category requirement.';
  public static NP_STATE_ERROR = 'Error fetching NP states';
  public static ERROR_FETCHING_STATE = 'Error fetching states';
  public static UPLOAD_ONLY_ONE_CERTIFICATE = 'You can upload only one certificate.';
  public static SUPPORTED_FILE_TYPE = 'Supported files are PDF and Image. Unknown type upload.';
  public static FILE_IS_TOO_LARGE = 'File is too large, please select file of less than 10mb size.';
  public static SELECT_INITIAL_LICENSE_DATE = 'Please select the initial license date.';
  public static PAST_INITIAL_DATE = 'Initial license date should be a past date.';

  public static PASSWORD_STRENGTH_ERROR =
    'Minimum 12 characters, one upper case letter, one lower case letter, one number, and one special character is required.';
  public static PASSWORD_MISMATCH = 'Password does not match. Please confirm the new password.';
  public static INVALID_CURRENT_PASSWORD = 'Please enter a valid current password.';
  public static EMPTY_NOTES = 'Please enter notes.';
  public static EMPTY_INITIAL_DATE = 'Must select Initial date.';
  public static OTHER_TITLE_CHARACTER_LENGTH = 'title should not contain more than 255 characters.';
}
